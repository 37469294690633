.container {
    margin-top: 50px;
}

.card-container {
    max-width: 600px;
    width: 95%;
}

.edit-icon {
    opacity: 1;
    pointer-events: auto;
    cursor: pointer;
}

.lock {
    cursor: pointer;
}

@media (min-width: 1251px) {
    .edit-icon {
        opacity: 0;
        pointer-events: none;
    }

    ion-item:hover .edit-icon {
        opacity: 1;
        pointer-events: auto;
        cursor: pointer;
    }
}