
.review-icon:hover, .review-icon-close:hover, .add-review-icon:hover {
    cursor: pointer;
    color: #428CFFFF;
}

.review-icon-close {
    padding-right: 15px;
}

.add-review-container {
    display: flex;
    padding-left: 20px;
}

.add-review-icon {
    margin: 20px;
}

.review-item-container {
    margin: 10px 0;
}